// @import url(https://fonts.bunny.net/css?family=poppins:100i,200,300,400,600i,800);

:root {
  --version: 'v1.0.0';
  --variant-name: 'MATX';
  --variant-emoji: '\1FABB\00A0';
  --variant: var(--variant-emoji) var(--variant-name);
  --meta: 'Tangerine UI MATX for Mastodon: ' var(--version) ' \00B7 '
    var(--variant);
}

.app-body .link-footer::after {
  content: '';
}

body {
  font-family: Poppins, sans-serif !important;
}

// morea iluna: #592c82
// morea argia: #f7f2fa
// morea bottom bar: #bcaacd
// more arrosa argia? : #fef7ff

// horia: #ffc72a

// grisa: #dadce0

@media screen and (width >= 1175px) {
  /* Order of the side nav items */
  .app-body .column-link[href='/matx'] {
    order: 15;
  }

  .column-link.column-link--matx-logo img {
    width: auto;
    height: 100px;
    margin: 0 auto;
  }
}

@media screen and (width <= 1174px) {
  .app-body:not(.layout-multiple-columns) .tabs-bar__wrapper {
    display: block;

    // top: 55px;
    top: 0;
    inset-inline-start: 55px;

    // inset-inline-start: 0px;
    inset-inline-end: 55px;
    height: 55px;
    position: fixed;
  }

  .app-body .columns-area__panels__pane--navigational .navigation-panel {
    background-color: #bcaacd;
    color: #fff;
  }

  .app-body
    .columns-area__panels__pane--navigational
    .columns-area__panels__pane__inner::before {
    background-image: none;
  }

  .app-body:not(.layout-multiple-columns) .ui::after {
    /* Set a backdrop blur background to both top bars */
    // height: 105px;
  }

  .app-body:not(.layout-multiple-columns) .ui::after {
    // height: 105;
  }

  .app-body .columns-area__panels {
    // margin-top: 50px;
  }

  .app-body:not(.layout-multiple-columns) .ui__header {
    // top: 50px;
  }

  .app-body .column-header__wrapper {
    background-color: transparent;
  }

  .ui__header__logo-matx img {
    width: 45px;
    height: auto;
    padding: 5px;
  }

  // .app-body .ui__header__links .button.button-secondary[href='/publish'] {
  //   background-color: #592c82;
  // }
}

.app-body .status__relative-time {
  background: none;

  // background-color: #f7f2fa;
}

.app-body .status__relative-time::before,
.app-body .status__relative-time::after {
  background: none;
}

.app-body .status__wrapper,
.app-body .detailed-status__wrapper {
  --color-post-bg: var(--color-content-bg);

  background-color: #f7f2fa;
  padding: 13px 12px;
  border-radius: 10px;
  border: 0;
}

article {
  padding: 5px;
}

.app-body .status::before,
.app-body .notification__message::before {
  background: none;
}

.app-body .detailed-status,
.app-body .detailed-status__action-bar {
  background: none;
}

.app-body .detailed-status__wrapper {
  margin: 5px;
}

.app-body .detailed-status__meta::before {
  background: none;
}

.app-body .scrollable > div:last-child > div:last-child > .status__wrapper,
.app-body .scrollable > div > article:last-child > div > .status__wrapper,
.app-body .search-results__section > div:last-child > .status__wrapper {
  border: 0;
}

// BACKEND

.flash-message.translation-prompt {
  display: none;
}

.admin-wrapper .sidebar .logo {
  display: block;
  margin: 5px auto;
  width: auto;
  height: 100px;
}

.matx-onboard-panel {
  border: 1px solid #eee;
  margin: 10px 0;
}

.matx-onboarding-input {
  min-width: 250px;
  border: 1px solid #ccc;
  border-radius: 3px;
  min-height: 30px;
  padding: 3px 10px;
}

.matx-onboarding-form-text {
  color: #777;
  margin-bottom: 15px;
}

.matx-onboarding-form {
  h4 {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .button {
    width: 100%;
    margin-top: 30px;
  }

  .form-error {
    color: brown;
    margin: 5px 0;
  }
}

.account__domain-pill {
  display: none;
}

// ::before

//   background-image: var(--icon-hashtag-accent);
//   background-position: 50%;
//   background-repeat: no-repeat;
//   background-size: contain;
//   content: " ";
//   display: block;
//   height: 20px;
//   min-width: 20px;
//   transform: scale(1.45);
//   width: 20px;
